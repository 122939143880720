/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import "./src/styles/global.css"

const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location
  const scrollToTopRoutes = ["/privacy-policy", "/terms-of-use"]

  if (scrollToTopRoutes.includes(pathname)) {
    window.scrollTo(0, 0, { behavior: "smooth" })
  }

  return false
}

export { shouldUpdateScroll }
